import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import Map from '@accrosoft-ltd/vf-careers-site-theme/src/components/Map';

import renderHTML from 'react-render-html';

import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';
import SEO from '@accrosoft-ltd/vf-careers-site-theme/src/components/seo';

import fetchJsonp from 'fetch-jsonp';

import 'url-search-params-polyfill';

import '@accrosoft-ltd/vf-careers-site-theme/src/components/leaflet.css';

import MiniAppFormForm from '@accrosoft-ltd/vf-careers-site-theme/src/components/miniAppFormForm';
import VF_Share_Buttons from '@accrosoft-ltd/vf-careers-site-theme/src/modules/SmartFeedIndividualComponents/VF_Share_Buttons';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics';
import { useTracking } from 'react-tracking';

const isBase64 = require('is-base64');

const base64url = require('base64url');

require('es6-promise').polyfill();

const {
  activateApplicationForm,
} = require('@accrosoft-ltd/vf-careers-site-theme/src/components/miniAppFormFormFunctions');

function calculateSalary(
  Currency,
  SalaryFrom,
  SalaryTo,
  SalaryType,
  SalaryPeriod
) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  let NewSalary;
  let NewSalaryFrom;
  let NewSalaryTo;

  let currencyFormatted = Currency;

  if (Currency === '&pound;') {
    currencyFormatted = '£';
  }

  if (SalaryTo === 0) {
    NewSalary = SalaryType;
  } else if (SalaryFrom === SalaryTo) {
    if (SalaryFrom >= 1000) {
      // Remove decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
    } else {
      // Add two decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
    }
    NewSalary = NewSalaryFrom + ' ' + SalaryPeriod;
  } else {
    if (SalaryFrom >= 1000) {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
    } else {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
    }
    if (SalaryTo >= 1000) {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
    } else {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
    }

    NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo + ' ' + SalaryPeriod;
  }
  return NewSalary;
}

function CheckJobHasNotExpired(JSONDate) {
  if (JSONDate) {
    let expDate = new Date(parseInt(JSONDate.substr(6), 10));
    let now = new Date();

    if (expDate > now) {
      return true;
    }

    return false;
  } else {
    return false;
  }
}

function FormatDateTime(JSONDate) {
  if (JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  } else {
    return JSONDate;
  }
}

function generateGoogleJobsSchema(vacancy) {
  return {
    '@context': 'http://schema.org',
    '@type': 'JobPosting',
    baseSalary: calculateSalary(
      vacancy.Currency,
      vacancy.SalaryFrom,
      vacancy.SalaryTo,
      vacancy.SalaryType,
      vacancy.SalaryPeriod
    ),
    jobBenefits: vacancy.Benefits,
    datePosted: FormatDateTime(vacancy.PostingDate),
    validThrough: FormatDateTime(vacancy.ExpiryDate),
    description: vacancy.Description,
    employmentType: vacancy.JobTypeTime,
    industry: vacancy.Industry,
    jobLocation: {
      '@type': 'Place',
      geo: {
        longitude: vacancy.Longitude,
        latitude: vacancy.Latitude,
        '@type': 'GeoCoordinates',
      },
      address: {
        '@type': 'PostalAddress',
        addressCountry: vacancy.Country,
        addressLocality: vacancy.Location,
        addressRegion: vacancy.Region,
        postalCode: vacancy.PostCode,
      },
    },
    occupationalCategory: vacancy.JobCategory,
    salaryCurrency: vacancy.Currency === '&pound;' ? 'GBP' : 'EUR',
    hiringOrganization: {
      '@type': 'Organization',
      name: vacancy.Company,
      logo: vacancy.CompanyLogoPath,
    },
    title: vacancy.JobTitle,
    workHours: vacancy.JobTypeTime,
  };
}

const listenForIFrameHeight = function listenForIFrameHeight() {
  if (typeof window !== 'undefined') {
    window.addEventListener(
      'message',
      function (event) {
        let careersPageiFrame = null;

        if (document.getElementById('vf_iFrame_application_form')) {
          careersPageiFrame = document.getElementById(
            'vf_iFrame_application_form'
          );
        }

        if (
          careersPageiFrame !== null &&
          careersPageiFrame !== undefined &&
          careersPageiFrame !== 'undefined'
        ) {
          let careersPageEvent = event.data[0];
          let careersPageHeight = event.data[1];

          switch (careersPageEvent) {
            case 'setCareersPageHeight':
              let careersPageHeightExtra = careersPageHeight + 50;

              console.debug('cp height => ', careersPageHeightExtra);

              careersPageiFrame.height = careersPageHeightExtra;
              break;
          }
        }
      },
      false
    );
  }
};

const getBackgroundImage = function getBackgroundImage(vacancy) {
  const imageDirURL = 'https://cdn1.accropress.com/Phoenix/photos/';
  //const imageDirURL = '/img/';

  let backgroundImageToReturn = imageDirURL + 'FacebookAd_Stylised_2048.jpg';

  if (vacancy.JobCategory === 'Childcare') {
    // if (vacancy.Location) {}
    // if (vacancy.SubLocation) {}

    backgroundImageToReturn =
      imageDirURL + '1024%20Social%20Media%20Pictures2.png';

    if (vacancy.Region === 'Mid Devon') {
      backgroundImageToReturn = imageDirURL + 'Tiverton-RSW-Image.png';
    }

    if (vacancy.Region === 'North Devon') {
      backgroundImageToReturn = imageDirURL + 'Bryn-Hylog.png';
    }

    if (vacancy.Region === 'Cornwall') {
      backgroundImageToReturn = imageDirURL + 'Bryn-Hylog.png';
    }

    if (vacancy.Region === 'South Wales') {
      if (vacancy.Location === 'Caerphilly') {
        backgroundImageToReturn = imageDirURL + 'Efail-Wen.png';
      }

      if (vacancy.Location === 'Merthyr Tydfil') {
        backgroundImageToReturn = imageDirURL + 'Bryn-Hylog.png';
      }

      if (vacancy.Location === 'Tredegar') {
        backgroundImageToReturn = imageDirURL + 'Bryn-Hylog.png';
      }

      if (vacancy.Location === 'Pontypridd') {
        backgroundImageToReturn = imageDirURL + 'Pren-RSW-Image.png';
      }
    }

    if (vacancy.Region === 'Somerset') {
      backgroundImageToReturn = imageDirURL + 'Tiverton-RSW-Image.png';
    }
  }

  if (vacancy.JobCategory === 'Adult Services') {
    // if (vacancy.Location) {}
    // if (vacancy.SubLocation) {}

    backgroundImageToReturn = imageDirURL + 'Supported-Living.png';
  }

  if (vacancy.JobCategory === 'Central Services') {
    // if (vacancy.Location) {}
    // if (vacancy.SubLocation) {}

    backgroundImageToReturn = imageDirURL + 'Screenshot-2022-06-06.png';
  }
  if (vacancy.JobCategory === 'Education') {
    // if (vacancy.Location) {}
    if (vacancy.SubLocation === 'Neptune School') {
      backgroundImageToReturn = imageDirURL + 'Neptune.png';
    }
    if (vacancy.SubLocation === 'Ysgol Tŷ Monmouth') {
      backgroundImageToReturn = imageDirURL + 'Ty%20Monmouth%20with%20logo.png';
    }

    if (vacancy.Region === 'Berkshire') {
      if (vacancy.Location === 'Thatcham') {
        backgroundImageToReturn = imageDirURL + 'The-Grange.png';
      }
    }

    if (vacancy.Region === 'South Gloucestershire') {
      if (vacancy.Location === 'Warmley') {
        backgroundImageToReturn = imageDirURL + 'Neptune-2022.png';
      }

      if (vacancy.Location === 'Ysgol Tŷ Monmouth') {
        backgroundImageToReturn = imageDirURL + 'Ty-Monmouth.png';
      }
    }
  }
  if (vacancy.JobCategory === 'Further Education') {
    if (vacancy.Location === 'Yate') {
      if (vacancy.SubLocation === 'Oakwood Specialist College') {
        backgroundImageToReturn = imageDirURL + 'Oakwood%20Yate.png';
      }
    }
    // if (vacancy.SubLocation) {}
  }

  console.debug('backgroundImageToReturn', backgroundImageToReturn);

  return backgroundImageToReturn;
};

const getBackgroundVideo = function getBackgroundVideo(vacancy) {
  const videoDirURL = 'https://cdn1.accropress.com/Phoenix/videos/';

  if (vacancy.JobCategory === 'Education') {
    // if (vacancy.Location) {}
    if (vacancy.SubLocation === 'Acorn School') {
      return videoDirURL + 'Acorn%20School.mp4';
    }
    if (vacancy.SubLocation === 'Phoenix Academy') {
      return videoDirURL + 'Phoenix%20Academy.mp4';
    }
  }
  if (vacancy.JobCategory === 'Further Education') {
    if (vacancy.Location === 'Torpoint') {
      if (vacancy.SubLocation === 'Oakwood Specialist College') {
        return videoDirURL + 'Oakwood%20Torpoint%201080p.mp4';
      }
    }
    if (vacancy.Location === 'Dawlish') {
      if (vacancy.SubLocation === 'Oakwood Specialist College') {
        return videoDirURL + 'Oakwood%20Dawlish%201080p.mp4';
      }
    }
    // if (vacancy.SubLocation) {}
  }

  return null;
};

const SmartfeedIndividual = ({
  id = 1,
  vacancyTitlePath = false,
  apiKey,
  groupOrIdParam,
  smartfeedCompanyData,
  appGatewayURL,
  vacanciesRouteLoading,
  vacanciesRouteError,
  location,
  siteConfig,
}) => {
  const params = new URLSearchParams(location.search);
  const extraDataEncoded = params ? params.get('ExtraData') : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split('|')
    : [];

  const [vacancy, setVacancy] = useState([]);
  const [showApplication, setshowApplication] = useState(false);
  const [applicationFormLoading, setApplicationFormLoading] = useState(false);
  const [miniAppFormToken, setMiniAppFormToken] = useState('');
  const [miniAppFormJSON, setMiniAppFormJSON] = useState('');
  const [gdprStatementJSON, setGdprStatementJSON] = useState('');
  const [jobSourcesJSON, getJobSourcesJSON] = useState([]);
  const [showMiniAppForm, setShowMiniAppForm] = useState(false);
  const [
    showMiniAppFormFrameLoading,
    setShowMiniAppFormFrameLoading,
  ] = useState(false);
  const [miniAppFormSubmitted, setMiniAppFormSubmitted] = useState(false);
  const [MiniAppError, setMiniAppError] = useState('');
  const [MiniAppFormIframe, setMiniAppFormIframe] = useState('');
  const [vacancyTitle, setVacancyTitle] = useState(
    extraDataDecodedSplit &&
      extraDataDecodedSplit[0] &&
      extraDataDecodedSplit[1]
      ? extraDataDecodedSplit[0] + ' | ' + extraDataDecodedSplit[1]
      : extraDataDecodedSplit && extraDataDecodedSplit[0]
      ? extraDataDecodedSplit[0]
      : 'Loading vacancy...'
  );
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [hasCVUploadedStatus, setHasCVUploadedStatus] = useState(null);

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    {
      page: 'SmartfeedIndividual',
      location,
      apiKey,
      id,
      AdvertReference: vacancy.AdvertReference,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, []);

  useEffect(() => {
    if (!vacanciesRouteLoading && !vacanciesRouteError) {
      listenForIFrameHeight();
      setshowApplication(false);

      let searchParams = new URLSearchParams(location.search);
      let entries = searchParams.entries();

      let vacancyPageAction = null;

      for (let pair of entries) {
        if (pair[0] === 'action') {
          vacancyPageAction = pair[1];
        }
      }

      setTimeout(function () {
        setLoadingText(
          <span>
            If this is taking too long, please{' '}
            <Link to="/">go back to the search page</Link> and re-select a
            vacancy.
          </span>
        );
      }, 3000);

      const timeOut = setTimeout(() => {
        if (isBase64(decodeURIComponent(id)) && vacancyTitlePath) {
          fetchVacancy(
            new Buffer(decodeURIComponent(id), 'base64').toString(),
            vacancyPageAction
          );
        } else {
          fetchVacancy(decodeURIComponent(id), vacancyPageAction);
        }
      }, 25);
      return () => clearTimeout(timeOut);
    }
  }, [id, vacanciesRouteLoading]);

  function vfScrollToElement(element, to, duration) {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = (difference / duration) * 10;

    setTimeout(function () {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) return;
      window.scrollTo(element, to, duration - 10);
    }, 10);
  }

  function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return;
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = from + step * (to - from) + unit;
        } else {
          elem.style[style] = from + step * (to - from) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 25);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }

  const fetchVacancy = async (id, vacancyPageAction) => {
    setLoading(true);
    try {
      fetchJsonp(siteConfig.sfAPIEndpoint + '/CareerPage/GetItem?id=' + id, {
        timeout: 15000,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const responseVacancy = json;
          const resultVacancy = responseVacancy;
          if (resultVacancy) {
            setLoading(false);
            setVacancy(resultVacancy);
            setVacancyTitle(resultVacancy.JobTitle);

            if (
              vacancyPageAction === 'applyNow' &&
              siteConfig.useMiniAppForm === true
            ) {
              trackEvent({
                eventAction: 'activateApplicationForm_QueryString',
                eventLabel: resultVacancy.AdvertReference,
              });
              activateApplicationForm(
                siteConfig,
                true,
                setshowApplication,
                setApplicationFormLoading,
                setMiniAppFormJSON,
                setGdprStatementJSON,
                getJobSourcesJSON,
                setShowMiniAppForm,
                setShowMiniAppFormFrameLoading,
                resultVacancy.AdvertId,
                resultVacancy.Token,
                resultVacancy.RequiresCv,
                MiniAppError,
                setMiniAppError,
                miniAppFormToken,
                setMiniAppFormToken,
                false,
                resultVacancy.CompanyId,
                trackEvent
              );

              setshowApplication(true);
            }

            return resultVacancy;
          }
        })
        .catch(function (ex) {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint +
              `/CareerPage/GetItem?id=${new Buffer(id, 'base64').toString()}`,
            {
              timeout: 15000,
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              const responseVacancy = json;
              const resultVacancy = responseVacancy;
              if (resultVacancy) {
                setLoading(false);
                setVacancy(resultVacancy);
                setVacancyTitle(resultVacancy.JobTitle);

                if (
                  vacancyPageAction === 'applyNow' &&
                  siteConfig.useMiniAppForm === true
                ) {
                  trackEvent({
                    eventAction: 'activateApplicationForm_QueryString',
                    eventLabel: resultVacancy.AdvertReference,
                  });
                  activateApplicationForm(
                    siteConfig,
                    true,
                    setshowApplication,
                    setApplicationFormLoading,
                    setMiniAppFormJSON,
                    setGdprStatementJSON,
                    getJobSourcesJSON,
                    setShowMiniAppForm,
                    setShowMiniAppFormFrameLoading,
                    resultVacancy.AdvertId,
                    resultVacancy.Token,
                    resultVacancy.RequiresCv,
                    MiniAppError,
                    setMiniAppError,
                    miniAppFormToken,
                    setMiniAppFormToken,
                    false,
                    resultVacancy.CompanyId,
                    trackEvent
                  );

                  setshowApplication(true);
                }

                return resultVacancy;
              }
            })
            .catch(function (ex) {
              setLoading(false);
              console.debug(ex, 'error');
            });
        });

      /*  
           JSON method, sadly doesn't work with SF
           const fetchingVacancy = await fetch(
              siteConfig.sfAPIEndpoint + `/CareerPage/GetItem?callback=gatsby&id=${id}`
            )
            const responseVacancy = await fetchingVacancy.json()
            const resultVacancy = await responseVacancy
            setLoading(false)
            setVacancy(resultVacancy)
            return resultVacancy 
            */
    } catch (error) {
      setLoading(false);
      console.debug(error.message, 'error');
    }
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      appGatewayURL={appGatewayURL}
      smartfeedCompanyData={smartfeedCompanyData}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      trackEvent={trackEvent}
    >
      <SEO
        siteConfig={siteConfig}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        title={
          loading
            ? extraDataDecodedSplit &&
              extraDataDecodedSplit[0] &&
              extraDataDecodedSplit[1]
              ? extraDataDecodedSplit[0] + ' | ' + extraDataDecodedSplit[1]
              : extraDataDecodedSplit && extraDataDecodedSplit[0]
              ? extraDataDecodedSplit[0]
              : 'Loading vacancy...'
            : vacancyTitle
        }
        description={
          loading
            ? extraDataDecodedSplit && extraDataDecodedSplit[4]
              ? extraDataDecodedSplit[4]
              : extraDataDecodedSplit && extraDataDecodedSplit[1]
              ? 'Check out this vacancy from ' + extraDataDecodedSplit[1]
              : 'Check out this vacancy.'
            : vacancy.Summary
        }
      />
      <div className="single-banner">
        <div className="banner page">
          {/* <div className="half-circle">
            <div className="half-circle-container">
              <div className="container">
                <div className="text-block">
                  {vacancy && vacancy != [] && vacancy.Description && (
                    <h1 className="vf-jobtitle">{vacancyTitle}</h1>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {vacancy && vacancy.JobCategory && (
            <>
              <div
                className="single-banner-background-image"
                style={{
                  background: `url(${getBackgroundImage(vacancy)})`,
                }}
              ></div>
              {getBackgroundVideo(vacancy) && (
                <div className="single-banner-background-video">
                  <video width="100%" height="100%" autoPlay muted={true} loop>
                    <source
                      src={`${getBackgroundVideo(vacancy)}`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
            </>
          )}
        </div>

        <div className="single-banner-background-content">
          {!vacanciesRouteLoading &&
            (!vacancy || vacancy === [] || !vacancy.Description) && (
              <div
                className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing"
                id="listing-top-single-loading"
              >
                <div className="col-12 col-lg-8">
                  <>
                    <h1>Loading vacancy...</h1>
                    <p className="small-padding">
                      Please wait while we load the vacancy that you requested.
                    </p>
                    {loadingText && <p>{loadingText}</p>}
                  </>
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="main-container main-container--single">
        {!vacanciesRouteLoading &&
          vacancy &&
          vacancy != [] &&
          vacancy.Description && (
            <div>
              <div className="single-vacancy-background container">
                <div id="ApplicationScrollTarget"></div>
                {!showApplication ? (
                  <>
                    {vacancy.PostingDate &&
                      vacancy.ExpiryDate &&
                      CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                        <script
                          type="application/ld+json"
                          dangerouslySetInnerHTML={{
                            __html: JSON.stringify(
                              generateGoogleJobsSchema(vacancy)
                            ),
                          }}
                        />
                      )}

                    <div className="page__header-container page__header-container--vacancies-landing page__header-container--vacancies-landing-single">
                      <div className="container">
                        <div
                          className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing page__header--vacancies-landing-single"
                          id="listing-top"
                        >
                          <div className="col-12">
                            <>
                              {vacancy && vacancy != [] && vacancy.Description && (
                                <>
                                  {vacancy &&
                                    vacancy != [] &&
                                    vacancy.Description && (
                                      <h1 className="vf-jobtitle">
                                        {vacancyTitle}
                                      </h1>
                                    )}
                                  <p className="vf-summary">
                                    {vacancy.Summary}
                                  </p>
                                  <div className="job-title-meta">
                                    <span>
                                      {'(Reference: ' +
                                        vacancy.AdvertReference +
                                        ')'}
                                    </span>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                          <div className="col-12">
                            <>
                              {vacancy && vacancy != [] && vacancy.Description && (
                                <div className="vf-details-wrap">
                                  <div className="vf-details">
                                    <div className="vf-job-info-container">
                                      <div className="vf-job-info-title">
                                        Location:
                                      </div>
                                      <div className="vf-job-info-value vf-Location">
                                        {vacancy.Region
                                          ? vacancy.Region + ', '
                                          : ''}
                                        {vacancy.Location
                                          ? vacancy.Location + ', '
                                          : ''}
                                        {vacancy.PostCode
                                          ? vacancy.PostCode + ', '
                                          : ''}
                                      </div>
                                    </div>
                                    <div className="vf-job-info-container">
                                      <div className="vf-job-info-title">
                                        Job Type:
                                      </div>
                                      <div className="vf-job-info-value">
                                        <span className="vf-category">
                                          {vacancy.JobType} -{' '}
                                          {vacancy.JobTypeTime}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="vf-job-info-container">
                                      <div className="vf-job-info-title">
                                        Job Category:
                                      </div>
                                      <div className="vf-job-info-value">
                                        <span className="vf-category">
                                          {vacancy.JobCategory}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="vf-job-info-container">
                                      <div className="vf-job-info-title">
                                        Salary:
                                      </div>
                                      <div className="vf-job-info-value vf-Salary">
                                        {calculateSalary(
                                          vacancy.Currency,
                                          vacancy.SalaryFrom,
                                          vacancy.SalaryTo,
                                          vacancy.SalaryType,
                                          vacancy.SalaryPeriod
                                        )}
                                      </div>
                                    </div>
                                    <div className="vf-job-info-container">
                                      <div className="vf-job-info-title">
                                        Closing Date:
                                      </div>
                                      <div className="vf-job-info-value vf-ExpiryDate">
                                        {FormatDateTime(vacancy.ExpiryDate)}
                                      </div>
                                    </div>
                                    <div className="vf-job-info-container">
                                      {vacancy.Attachments &&
                                        vacancy.Attachments != [] &&
                                        vacancy.Attachments.length >= 0 && (
                                          <ul className="vf-Attachments">
                                            {vacancy.Attachments &&
                                              vacancy.Attachments.map(
                                                (attachment, i) => (
                                                  <li
                                                    key={i}
                                                    id={attachment.S3Name}
                                                    className="row"
                                                  >
                                                    <a
                                                      className="btn-filedownload vf-row"
                                                      href={attachment.Location}
                                                      target="_blank"
                                                    >
                                                      <span className="details vf-col-xs-8">
                                                        <div className="fal fa-download"></div>
                                                        <span className="filename">
                                                          {'Download ' +
                                                            attachment.FileName.substring(
                                                              0,
                                                              19
                                                            )}
                                                          {attachment.FileName
                                                            .length > 19
                                                            ? '...'
                                                            : ''}
                                                        </span>
                                                      </span>
                                                    </a>
                                                  </li>
                                                )
                                              )}
                                          </ul>
                                        )}
                                    </div>
                                  </div>
                                  <div className="vf-details-actions">
                                    {vacancy.PostingDate &&
                                      vacancy.ExpiryDate &&
                                      CheckJobHasNotExpired(
                                        vacancy.ExpiryDate
                                      ) && (
                                        <>
                                          {siteConfig.useMiniAppForm ===
                                          true ? (
                                            <a
                                              className="btn--action"
                                              onClick={() => {
                                                trackEvent({
                                                  eventAction:
                                                    'activateApplicationForm',
                                                  eventLabel:
                                                    vacancy.AdvertReference,
                                                });
                                                activateApplicationForm(
                                                  siteConfig,
                                                  true,
                                                  setshowApplication,
                                                  setApplicationFormLoading,
                                                  setMiniAppFormJSON,
                                                  setGdprStatementJSON,
                                                  getJobSourcesJSON,
                                                  setShowMiniAppForm,
                                                  setShowMiniAppFormFrameLoading,
                                                  vacancy.AdvertId,
                                                  vacancy.Token,
                                                  vacancy.RequiresCv,
                                                  MiniAppError,
                                                  setMiniAppError,
                                                  miniAppFormToken,
                                                  setMiniAppFormToken,
                                                  false,
                                                  vacancy.CompanyId,
                                                  trackEvent
                                                );
                                              }}
                                            >
                                              Apply now
                                            </a>
                                          ) : (
                                            <a
                                              target="_blank"
                                              href={
                                                vacancy.Link +
                                                '&action=ApplyNow&iframe=False'
                                              }
                                              className="btn--action"
                                            >
                                              Apply now
                                            </a>
                                          )}
                                          {/* <br></br>
                                          <div className="vf-indeed-apply-wrap">
                                            {vacancy.IndeedApplyButtonHTML && (
                                              <>
                                                {renderHTML(
                                                  vacancy.IndeedApplyButtonHTML
                                                )}
                                              </>
                                            )}
                                          </div> */}
                                          <br></br>
                                          <Link
                                            className="btn--action-secondary btn btn--secondary"
                                            id="back-to-careers-link"
                                            to={`/`}
                                          >
                                            Back to careers
                                          </Link>
                                          <div id="share-icons">
                                            <div className="vf-SocialButtons">
                                              <div className="social-icons">
                                                <VF_Share_Buttons
                                                  vacancyTitle={vacancyTitle}
                                                  vacancy={vacancy}
                                                  apiKey={apiKey}
                                                  siteConfig={siteConfig}
                                                  text="Share this role instead:"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="vacancies-landing">
                      <div className="container ">
                        <div className="row clearfix">
                          <div className="col-md-12 column">
                            <div className="smartfeed-spacing">
                              <div className="row vacancyDetailsWrap">
                                <div className="col-12 job-description">
                                  <div className="vf-JobDescription">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: vacancy.Description,
                                      }}
                                    />
                                  </div>

                                  <div className="JobDescription benefits">
                                    <h2>Benefits:</h2>
                                    <p className="vf-benefits">
                                      {vacancy.Benefits}
                                    </p>
                                  </div>

                                  <div className="vf-map">
                                    {vacancy.Latitude != '' &&
                                      vacancy.Latitude != null &&
                                      vacancy.Latitude != 0 &&
                                      vacancy.Longitude != '' &&
                                      vacancy.Longitude != null &&
                                      vacancy.Longitude != 0 && (
                                        <Map
                                          latitude={Number(vacancy.Latitude)}
                                          longitude={Number(vacancy.Longitude)}
                                          zoom={12}
                                          scrollWheelZoom={false}
                                          tooltipText={vacancy.JobTitle}
                                        />
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <section>
                      <div>
                        <div className="container ">
                          <div className="row clearfix">
                            <div className="col-md-12 column">
                              {' '}
                              <div>
                                <div className="smartfeed-spacing">
                                  <article tabIndex="-1">
                                    <div className="ctacard ctacard-application">
                                      <div className="ctacard__body d-flex justify-content-center">
                                        <div className="col-12">
                                          <h4 className="ctacard__body__title">
                                            Apply for this role
                                          </h4>
                                          <p className="ctacard__body__summary"></p>
                                          <p>
                                            Submit your application today with
                                            our simple application process.
                                          </p>
                                          <p></p>
                                          {vacancy.PostingDate &&
                                            vacancy.ExpiryDate &&
                                            CheckJobHasNotExpired(
                                              vacancy.ExpiryDate
                                            ) && (
                                              <>
                                                {siteConfig.useMiniAppForm ===
                                                true ? (
                                                  <a
                                                    className="btn--action"
                                                    onClick={() => {
                                                      trackEvent({
                                                        eventAction:
                                                          'activateApplicationForm',
                                                        eventLabel:
                                                          vacancy.AdvertReference,
                                                      });
                                                      activateApplicationForm(
                                                        siteConfig,
                                                        true,
                                                        setshowApplication,
                                                        setApplicationFormLoading,
                                                        setMiniAppFormJSON,
                                                        setGdprStatementJSON,
                                                        getJobSourcesJSON,
                                                        setShowMiniAppForm,
                                                        setShowMiniAppFormFrameLoading,
                                                        vacancy.AdvertId,
                                                        vacancy.Token,
                                                        vacancy.RequiresCv,
                                                        MiniAppError,
                                                        setMiniAppError,
                                                        miniAppFormToken,
                                                        setMiniAppFormToken,
                                                        false,
                                                        vacancy.CompanyId,
                                                        trackEvent
                                                      );
                                                    }}
                                                  >
                                                    Apply now
                                                  </a>
                                                ) : (
                                                  <a
                                                    target="_blank"
                                                    href={
                                                      vacancy.Link +
                                                      '&action=ApplyNow&iframe=False'
                                                    }
                                                    className="btn--action"
                                                  >
                                                    Apply now
                                                  </a>
                                                )}
                                              </>
                                            )}
                                          <div className="vf-JobActions">
                                            <div id="share-icons">
                                              <div className="vf-SocialButtons">
                                                <VF_Share_Buttons
                                                  vacancyTitle={vacancyTitle}
                                                  vacancy={vacancy}
                                                  apiKey={apiKey}
                                                  siteConfig={siteConfig}
                                                  text="Share this role instead:"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <div className="vacancy-application-wrapper">
                    <div className="vacancy-application-topbar vacancy-application-topbar---standard-app">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-lg-6 job-title-app">
                            <h1>{vacancyTitle + ' application'}</h1>
                          </div>
                          <div className="col-12 col-lg-6 back-to-description">
                            <a
                              className="nav-action-sm"
                              onClick={() => {
                                trackEvent({
                                  eventAction: 'deactivateApplicationForm',
                                  eventLabel: vacancy.AdvertReference,
                                });
                                activateApplicationForm(
                                  siteConfig,
                                  false,
                                  setshowApplication,
                                  setApplicationFormLoading,
                                  setMiniAppFormJSON,
                                  setGdprStatementJSON,
                                  getJobSourcesJSON,
                                  setShowMiniAppForm,
                                  setShowMiniAppFormFrameLoading,
                                  vacancy.AdvertId,
                                  vacancy.Token,
                                  vacancy.RequiresCv,
                                  MiniAppError,
                                  setMiniAppError,
                                  miniAppFormToken,
                                  setMiniAppFormToken,
                                  false,
                                  vacancy.CompanyId,
                                  trackEvent
                                );
                              }}
                            >
                              Go back to the description
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {siteConfig.useMiniAppForm === true ? (
                      <div id="miniAppFormWrapper">
                        <div className="container">
                          <div id="miniAppForm">
                            {miniAppFormJSON &&
                            miniAppFormJSON.config &&
                            miniAppFormJSON.config.Fields ? (
                              <MiniAppFormForm
                                siteConfig={siteConfig}
                                showMiniAppForm={showMiniAppForm}
                                applicationFormLoading={applicationFormLoading}
                                miniAppFormJSON={miniAppFormJSON}
                                setMiniAppFormJSON={setMiniAppFormJSON}
                                miniAppFormToken={miniAppFormToken}
                                setMiniAppFormToken={setMiniAppFormToken}
                                gdprStatementJSON={gdprStatementJSON}
                                jobSourcesJSON={jobSourcesJSON}
                                setShowMiniAppForm={setShowMiniAppForm}
                                showMiniAppFormFrameLoading={
                                  showMiniAppFormFrameLoading
                                }
                                setShowMiniAppFormFrameLoading={
                                  setShowMiniAppFormFrameLoading
                                }
                                miniAppFormSubmitted={miniAppFormSubmitted}
                                setMiniAppFormSubmitted={
                                  setMiniAppFormSubmitted
                                }
                                MiniAppFormIframe={MiniAppFormIframe}
                                setMiniAppFormIframe={setMiniAppFormIframe}
                                MiniAppError={MiniAppError}
                                setMiniAppError={setMiniAppError}
                                hasCVUploadedStatus={hasCVUploadedStatus}
                                setHasCVUploadedStatus={setHasCVUploadedStatus}
                                trackEvent={trackEvent}
                              />
                            ) : (
                              <div className="loading-panel">
                                <div className="loading-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                <span className="loading-text">
                                  Please wait while we load the form's
                                  configuration...
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="vf_iFrame_wrapper">
                        <div className="container">
                          <iframe
                            id="vf_iFrame_application_form"
                            src={
                              vacancy.Link +
                              '&action=ApplyNow&HideCareersLink=true'
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    </Layout>
  );
};

export default SmartfeedIndividual;
