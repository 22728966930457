import React, { useState, useEffect } from "react"
import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/layout"
import { Helmet } from "react-helmet"

import SavedJobsList from "@accrosoft-ltd/vf-careers-site-theme/src/components/SavedJobsList"

import { useCookies } from 'react-cookie';
import { trackAnalyticsEvent, trackAnalyticsPageView, useStateWithSessionStorage } from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics'
import { useTracking } from 'react-tracking';

require("es6-promise").polyfill()

export default function SmartFeedSavedList(props) {
  const [vacancySavedLoading, setVacancySavedLoading] = useState(true)

  const siteConfig = props.siteConfig

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking({ page: "SmartFeedSavedList", location: props.location, apiKey: props.apiKey }, {
    dispatch: data => trackAnalyticsEvent(data, cookies['ap-signals-user-id'], sessionIDValue, siteConfig)
  });

  useEffect(() => {
    trackAnalyticsPageView(props.location, cookies['ap-signals-user-id'], sessionIDValue, siteConfig)
  }, [])

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={trackEvent}
    >
      {!props.vacanciesRouteLoading &&
        props.smartfeedCompanyData &&
        props.apiKey &&
        props.groupOrIdParam && (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{`${siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
                } Careers`}</title>
              <meta
                name="description"
                content={`Careers at ${siteConfig.CompanyName ||
                  props.smartfeedCompanyData.CompanyName
                  }`}
              />
            </Helmet>
            <SavedJobsList
              trackEvent={trackEvent}
              siteConfig={siteConfig}
              setVacancySavedLoading={setVacancySavedLoading}
              vacancySavedLoading={vacancySavedLoading}
              apiKey={siteConfig.apiKey || props.apiKey}
              group={
                siteConfig.group
                  ? siteConfig.group
                  : props.groupOrIdParam === "id"
                    ? false
                    : props.groupOrIdParam === "group"
                      ? true
                      : false
              }
              gdprKey={siteConfig.gdprKey || siteConfig.apiKey || props.apiKey}
              smartfeedCompanyData={
                siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
              }
              CompanyGroupName={
                siteConfig.companyName || props.smartfeedCompanyData.CompanyName
              }
              OptInLink={
                siteConfig.OptInLink ||
                `https://careers-site.vacancyfiller.com/job-alerts/${props.apiKey}/optin/?OptInID=`
              }
              UnsubscribeLink={
                siteConfig.UnsubscribeLink ||
                `https://careers-site.vacancyfiller.com/job-alerts/${props.apiKey}/unsubscribe/?UnsubscribeID=`
              }
              VacancyPageBaseURL={
                siteConfig.VacancyPageBaseURL ||
                `https://careers-site.vacancyfiller.com/vacancies/${props.apiKey}/vacancy`
              }
              ReturnToSearchURL={
                siteConfig.ReturnToSearchURL ||
                `https://careers-site.vacancyfiller.com/vacancies/${props.apiKey}/`
              }
              contentDataData={props.contentDataData || {}}
              location={siteConfig.showLocationJA}
              region={siteConfig.showRegionJA}
              category={siteConfig.showCategoryJA}
              industry={siteConfig.showIndustryJA}
              jobType={siteConfig.showJobTypeJA}
              jobTime={siteConfig.showJobTimeJA}
            />          
          </>
        )}
    </Layout>
  )
}
